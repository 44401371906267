import React from 'react';
import './impressum.scss';

import Section9 from "../components/Section9/Section9";
import Heading from '../components/Heading/Heading';

export default function Impressum() {

    return (
    <div>
        <Heading/>
        <div className="ContainerImpressum">
            <div className="ContentImpressum">
                <div className="HeadingImpressum">
                    Impressum
                </div>
                <div className="Address">
                    <div>
                        akquinet tech@spree GmbH
                    </div>
                    <div>
                        Bülowstraße 66
                    </div>
                    <div>
                        10783 Berlin
                    </div>
                    <div>
                        Telefon +49 30 2355 20-0
                    </div>
                    <div>
                        E-Mail <a className="ImpressumLink" href="mailto:info@akquinet.de">info@akquinet.de</a>
                    </div>
                    <div>
                        Website <a className="ImpressumLink" href="https://www.akquinet.com">www.akquinet.com</a>
                    </div>
                    <br/>
                    <div>
                        Registergericht: Berlin (Charlottenburg)
                    </div>
                    <div>
                        Registernummer: HRB 86780 B
                    </div>
                    <br/>
                    <div>
                        vertreten durch:
                    </div>
                </div>
                <div className="Representatives">
                    <ul>
                        <li>Heinz Wilming</li>
                        <li>Dr. Torsten Fink</li>
                    </ul>
                </div>
                <div className="HeadingLiabilityNotice">Haftungshinweis</div>
                <div className="TextLiabilityNotice">
                    Die akquinet tech@spree GmbH übernimmt keine Gewähr für die Aktualität, Richtigkeit und Vollständigkeit 
                    der Informationen auf diesen Seiten oder den jederzeitigen störungsfreien Zugang. 
                    Wenn wir auf Internetseiten Dritter verweisen (Links), übernimmt akquinet tech@spree GmbH
                    trotz sorgfältiger inhaltlicher Kontrolle keine Haftung für die Inhalte externer Links. 
                    Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. 
                    Mit dem Betätigen des Verweises verlassen Sie das Informationsangebot der akquinet tech@spree GmbH. 
                    Weiterhin schließt akquinet tech@spree GmbH ihre Haftung bei Serviceleistungen aus, 
                    insbesondere beim Download von der akquinet tech@spree GmbH zur Verfügung gestellten Dateien 
                    auf den Internetseiten der akquinet tech@spree GmbH.
                </div>
            </div>     
        </div>    
        <Section9/>
    </div>
    );
}